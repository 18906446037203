import React from 'react';

import './Footer.css';

const Footer = props => {

    return (
        <div className='Footer'></div>
      )
};

export default Footer;